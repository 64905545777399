const resolveBaseUrl = () => {
  //eslint-disable-line
  const testUrl = "https://eyfort-api.eygo.biz";
  const env = process.env.NODE_ENV;
  const baseUrl = ["test", "development"].includes(env)
    ? testUrl
    : process.env.REACT_APP_API_URL;

  return testUrl;
};

export default resolveBaseUrl;
