import { IField } from "../interfaces/inputs/IField";

export const SensorTypeFields: IField[] = [
  { name: "label", type: "text", label: "Name", size: {lg: 6}, position: 1 },
  { name: "category", type: "text", label: "Category", size: {lg: 6}, position: 3 },
  { name: "wifi", type: "checkbox", label: "WIFI", size: {lg: 4}, position: 7 },
  { name: "rf", type: "checkbox", label: "RF", size: {lg: 4}, position: 8 },
  { name: "bluetooth", type: "checkbox", label: "BTE", size: {lg: 4}, position: 8 },
  { name: "ethernet", type: "checkbox", label: "ETH", size: {lg: 4}, position: 9 },
  { name: "transmitter", type: "checkbox", label: "TX", size: {lg: 4}, position: 10 },
  { name: "receiver", type: "checkbox", label: "RX", size: {lg: 4}, position: 11 },
].sort((a: IField, b: IField) => a.position - b.position);