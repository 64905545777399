import { Redirect } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//  Inner Authentication
import LoginPage from "../pages/Authentication/LoginPage";
import CreateAccount from "../pages/Authentication/CreateAccount";
import Signout from "../pages/Authentication/Signout";
import ResetPasswordPage from "../pages/Authentication/ResetPasswordPage";

//Error
import Error1 from "../pages/Error/Error1";
import Error2 from "../pages/Error/Error2";
import Error3 from "../pages/Error/Error3";
import ErrorBasic from "../pages/Error/ErrorBasic";
import ErrorCover from "../pages/Error/ErrorCover";

//utility
import PagesComingsoon from "../pages/Utility/PageComingsoon";
import PageMaintenance from "../pages/Utility/PageMaintenance";


import CreateBusiness from "../pages/Authentication/CreateBusiness";
import SelectProducts from "../pages/Authentication/SelectProducts";
import Assets from "../pages/Assets/list";
import AssetDetails from "../pages/Assets/details";

import Accessories from "../pages/Accessories/list";
import Sensortypes from "../pages/SensorTypes/list";
import Sensors from "../pages/Sensors/list";
import AccessoryDetails from "../pages/Accessories/details";

import Tenants from "../pages/Tenants/index";

import Uploads from "../pages/Uploads";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
  clazz?: string,
  ability?: string
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/dashboard", component: Assets },


  { path: "/uploads", component: Uploads },

  { path: "/assets", component: Assets },
  { path: "/assets/:id", component: AssetDetails },
  { path: "/accessories", component: Accessories },
  { path: "/accessories/:id", component: AccessoryDetails },
  { path: "/sensortypes", component: Sensortypes },
  { path: "/sensors", component: Sensors },

  { path: "/", exact: true, component: () => <Redirect to="/login" /> }
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: LoginPage },
  { path: "/logout", component: Signout },
  { path: "/register", component: CreateAccount },
  { path: "/register/business", component: CreateBusiness },
  { path: "/register/business/products", component: SelectProducts },
  { path: "/auth/password/reset", component: ResetPasswordPage },

  { path: "/error-404-basic", component: Error1 },
  { path: "/error-404-cover", component: Error2 },
  { path: "/error-403-basic", component: Error3 },
  { path: "/error-500-basic", component: ErrorBasic },
  { path: "/error-500-cover", component: ErrorCover },

  //utility page
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-maintenance", component: PageMaintenance }
];

export { userRoutes, authRoutes };
