interface MenuItemsProps {
    id: number;
    novidade ?: any;
    label: string;
    icon ?: string;
    link ?: string;
    badge?:string;
    badgecolor?:string;
    businessCategory?:string;
    subItems ?: any;
    isHeader ?: boolean
}

const menuItems: Array<MenuItemsProps> = [
    {
        id: 1,
        label: "Settings",
        isHeader : true
    },
    {
        id: 140,
        label: "Assets",
        icon: "th",
        link: "/assets"
    },{
        id: 138,
        label: "Accessories",
        icon: "th",
        link: "/accessories"
    },{
        id: 138,
        label: "Sensor types",
        icon: "th",
        link: "/sensortypes"
    },{
        id: 138,
        label: "Sensors",
        icon: "th",
        link: "/sensors"
    },
];

export { menuItems };
