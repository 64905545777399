import React, { useState } from "react";
import { Container, Row, Col, Label, Button, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import MetaTags from "react-meta-tags";

import { Formik, Field, Form } from "formik";

//import images
import logoLight from "../../assets/images/logo-white.png";
import logoDark from "../../assets/images/logo-colored.svg";
import { IUser } from "../../interfaces/IUser";
import { SignupSchema } from "../../schemas/SignupSchema";

const CreateAccount = () => {

  const user: IUser = {
    name: "",
    telephone: "",
    email: "",
    password: ""
  };


  const handleSubmit = (values: any) => {
    console.log(values)
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Create your EYFORT account</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>
                <div className="py-5">
                  <div className="text-center mb-4 mb-md-5">
                    <Link to="/sales" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="50" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="50" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h5 className="">Create an account</h5>
                    <p>Get your free EYFORT account now.</p>
                  </div>

                  <Formik validationSchema={SignupSchema}
                          initialValues={user}
                          onSubmit={(values) => handleSubmit(values)}>

                    {({isSubmitting, errors, touched}) => (
                      <Form>
                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="name" type="text"
                                 className={`form-control ${touched.name && errors.name && "is-invalid"}`}
                                 id="input-name"
                                 placeholder="Enter your name" />
                          <Label htmlFor="input-name">Name</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-user-alt ${touched.name && errors.name && "text-danger"}`}></i>
                          </div>
                        </div>

                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="email" type="email"
                                 className={`form-control ${touched.email && errors.email && "is-invalid"}`}
                                 id="input-email"
                                 placeholder="Enter email address" />
                          <Label htmlFor="input-email">Email</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-envelope ${touched.email && errors.email && "text-danger"}`}></i>
                          </div>
                        </div>

                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="telephone"
                                 type="tel"
                                 className={`form-control ${touched.telephone && errors.telephone && "is-invalid"}`}
                                 id="input-telephone" placeholder="Enter telephone" />
                          <Label htmlFor="input-telephone">Telephone</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-phone-alt ${touched.telephone && errors.telephone && "text-danger"}`}></i>
                          </div>
                        </div>

                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="password" type="password"
                                 className={`form-control ${touched.password && errors.password && "is-invalid"}`}
                                 id="input-password"
                                 placeholder="Enter password" />
                          <Label htmlFor="input-password">Password</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-lock ${touched.password && errors.password && "text-danger"}`}></i>
                          </div>
                        </div>

                        <div className="mt-3">
                          <Button color="primary"
                                  disabled={isSubmitting}
                                  className="btn btn-primary w-100" type="submit">
                            {isSubmitting ? <Spinner size={"sm"}></Spinner> : "Create account"}
                          </Button>
                        </div>
                      </Form>
                    )}


                  </Formik>


                  <div className="mt-5 text-center">
                    <p>Already have an account?<Link to="login"
                                                     className="fw-medium text-decoration-underline"> Signin </Link></p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="text-center text-muted p-4">
                  <p className="mb-0">&copy; {new Date().getFullYear()} Treda.</p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateAccount;