import { IField } from "../interfaces/inputs/IField";
import { Endpoints } from "../utils/Endpoints";

export const SensorFields: IField[] = [
  { name: "title", type: "text", label: "Title", size: {lg: 4}, position: 1 },
  { name: "location", type: "text", label: "Location", size: {lg: 4}, position: 2 },
  { name: "serial", type: "text", label: "Serial", size: {lg: 4}, position: 3 },
  { name: "address", type: "text", label: "Address", size: {lg: 4}, position: 3 },
  { name: "port", type: "text", label: "Port", size: {lg: 4}, position: 4 },
  { name: "sensorType[id]", type: "select", label: "Type", labelField: "label", dataSrc: Endpoints.sensortypes, size: {lg: 4}, position: 5 },
  { name: "online", type: "checkbox", label: "Online", size: {lg: 4}, position: 11 },
].sort((a: IField, b: IField) => a.position - b.position);