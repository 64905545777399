import { IField } from "../interfaces/inputs/IField";

const productTypes = [
  {value: 'service', text: 'Service'},
  {value: 'consumable', text: 'Consumable'},
  {value: 'stockable', text: 'Stockable'},
]

export const AccessoryFields: IField[] = [
  { name: "name", type: "text", label: "Name", size: {lg: 4}, position: 1 },
  { name: "location", type: "text", label: "Location", size: {lg: 4}, position: 2 },
  { name: "category", type: "text", label: "Category", size: {lg: 4}, position: 3 },
  { name: "serial", type: "text", label: "Serial", size: {lg: 4}, position: 4 },
  { name: "address", type: "text", label: "IP Address", size: {lg: 4}, position: 5 },
  { name: "port", type: "text", label: "Port", size: {lg: 4}, position: 6 },
  { name: "state", type: "checkbox", label: "State", size: {lg: 4}, position: 7 },
  { name: "online", type: "checkbox", label: "Online", size: {lg: 4}, position: 8 },
].sort((a: IField, b: IField) => a.position - b.position);