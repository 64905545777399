import React, { useState, useEffect } from "react";
import { IModalProps } from "../../interfaces/IModalProps";
import { Button, Modal } from "reactstrap";
import 'ol/ol.css';
import { Feature, Map, View } from 'ol';
import TileLayer from 'ol/layer/Tile';
import { XYZ } from 'ol/source';
import { fromLonLat } from 'ol/proj';
import { Point, SimpleGeometry } from 'ol/geom';
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Icon from "ol/style/Icon";
import Style from "ol/style/Style";
import Overlay from 'ol/Overlay';
import { click } from 'ol/events/condition';
import { Select } from 'ol/interaction';

const EyfortMap = (props: any) => {

  const { height = '400px' } = props;
  useEffect(() => {
    const map = new Map({
      target: 'map',
      layers: [
        new TileLayer({
          source: new XYZ({
            url: 'http://tile.eygo.biz/osm/{z}/{x}/{y}.png', // Replace with your tile server URL
          }),
        }),
      ],
      view: new View({
        center: fromLonLat([32.529627, 0.261077]),
        zoom: 8,
      }),
    });

    const marker = new Feature({
      geometry: new Point(fromLonLat([32.529627, 0.261077])), // Longitude, Latitude
    });

    marker.setStyle(
      new Style({
        image: new Icon({
          anchor: [0.5, 46],
          anchorXUnits: 'fraction',
          anchorYUnits: 'pixels',
          src: 'https://openlayers.org/en/latest/examples/data/icon.png', // URL to your marker image
        }),
      })
    );

    const vectorSource = new VectorSource({
      features: [marker],
    });

    const vectorLayer = new VectorLayer({
      source: vectorSource,
    });
    map.addLayer(vectorLayer);

    const select = new Select({
      condition: click,
    });

    map.addInteraction(select);

  }, []);

  return (
    <div id="map" style={{ width: '100%', height: height }}></div>
  );
};

export default EyfortMap;