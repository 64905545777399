import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { ProductFields } from "../../fields/ProductFields";
import { ProductGroupSchema, ProductSchema } from "../../schemas/ProductSchema";
import { Endpoints } from "../../utils/Endpoints";
import { IProduct } from "../../interfaces/products/IProduct";
import { Divider } from "@material-ui/core";
import { ProductGroupFields } from "../../fields/ProductGroupFields";
import { SensorTypeFields } from "../../fields/SensorTypeFields";

const SensorTypes = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [sensortypes, setSensorTypes] = useState<any>([]);
  const [showSensorTypeDialog, setShowSensorTypeDialog] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    code: ""
  });

  const columns = [
    {
      dataField: "label",
      text: "Name"
    },
    {
      dataField: "category",
      text: "Category"
    },
    {
      dataField: "transmitter",
      text: "Transmitter"
    },
    {
      dataField: "receiver",
      text: "Receiver"
    },
    {
      dataField: "bluetooth",
      text: "Bluetooth"
    },
    {
      dataField: "ethernet",
      text: "Ethernet"
    },
    {
      dataField: "rf",
      text: "RF"
    },
    {
      dataField: "wifi",
      text: "WIFI"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption(product, "edit")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowSensorTypeDialog(true);
    } else if (type == "new") {
      setInitialValues({
        name: "",
        sensortype: ""
      });
      setShowSensorTypeDialog(true);
    }
  }  

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 200,
    totalSize: sensortypes.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {

    request.get(Endpoints.sensortypes, {})
      .then((response: AxiosResponse) => {

        const sensortypes = response.data;
        setSensorTypes(sensortypes)
        setLoading(false)

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpdate = (item: any) => {
    const items = sensortypes.map((i: any) => {
      if (i.id === item.id){
        return {...i, ...item};
      }

      return i;
    })
    setSensorTypes(items);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Sensor Types - EYFORT</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Sensors" breadcrumbItem="Types" />

          <XModal title={"Add an sensor type"}
                  width={"lg"}
                  open={showSensorTypeDialog}
                  toggle={() => setShowSensorTypeDialog(!showSensorTypeDialog)}
                  onClose={() => setShowSensorTypeDialog(false)}>

            <CreateForm
              fields={SensorTypeFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.sensortypes + "/" + initialValues.id : Endpoints.sensortypes}
              submitButtonLabel={initialValues.id ? "Edit Sensor Type" : "Add Sensor Type"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowSensorTypeDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Sensor Types
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                        Add sensor type
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading sensortypes. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={sensortypes}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col lg="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table table-hover table-check align-middle table-nowrap"}
                                      headerWrapperClasses={"bg-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default SensorTypes;