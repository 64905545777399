import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  CardHeader,
  CardTitle,
  Container,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";

import { ApiCore } from "../../helpers/api_helper";
import { AxiosResponse } from "axios";
import { Endpoints } from "../../utils/Endpoints";
import { Loading } from "../../components/common/Loading";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'ol/ol.css';
import EyfortMap from "../../components/common/Map";

const AssetDetail = () => {
  const [asset, setAsset] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const { id } = useParams();
  const history = useHistory();

  const request = new ApiCore();

  useEffect(() => {
    request
      .get(Endpoints.assets + "/" + id)
      .then((response: AxiosResponse) => {
        setAsset(response.data);
        setLoading(false);
      })
      .catch(error => {
        console.error(error);
      });
      
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="page-content">
        <MetaTags>
          <title>Asset Details | Treda</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Assets" breadcrumbItem="Asset Detail" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  {loading && <Loading label="Loading asset. Please wait." />}
                  {!loading && (
                    <>
                    <div>
                      <b>LABEL:</b> {asset.label}&nbsp;&nbsp;<b>IMEI:</b> {asset.imei}&nbsp;&nbsp;<b>PHONE:</b> {asset.phone}
                      &nbsp;&nbsp;<b>CATEGORY:</b> {asset.category}
                    </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <br/>
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  {loading && <Loading label="Loading asset. Please wait." />}
                  {!loading && (
                    <>
                        <div className="table-responsive">
                          <table className="table align-middle table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th>Date/Time</th>
                                <th>Lat</th>
                                <th>Lon</th>
                                <th>Speed</th>
                                <th>Bearing</th>
                              </tr>
                            </thead>
                            <tbody>
                              {asset.positions.map((item: any, index: number) => {

                                return (
                                  <tr key={item.id}>
                                    <td>{item.timestamp}</td>
                                    <td>{item.latitude}</td>
                                    <td>{item.longitude}</td>
                                    <td>{item.speed}</td>
                                    <td>{item.bearing}</td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>
              <Card>
                <CardBody>
                  {loading && <Loading label="Loading asset. Please wait." />}
                  {!loading && (
                    <>
                    <EyfortMap />
                    <br/>
                    <div>
                      <b>LABEL:</b> {asset.label}&nbsp;&nbsp;<b>IMEI:</b> {asset.imei}&nbsp;&nbsp;<b>PHONE:</b> {asset.phone}
                      &nbsp;&nbsp;<b>CATEGORY:</b> {asset.category}
                    </div>
                    </>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AssetDetail;
