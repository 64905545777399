import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText
} from "reactstrap";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { ProductFields } from "../../fields/ProductFields";
import { ProductGroupSchema, ProductSchema } from "../../schemas/ProductSchema";
import { Endpoints } from "../../utils/Endpoints";
import { IProduct } from "../../interfaces/products/IProduct";
import { Divider } from "@material-ui/core";
import { ProductGroupFields } from "../../fields/ProductGroupFields";
import { AssetFields } from "../../fields/AssetFields";
import { useHistory } from "react-router-dom";

const Assets = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [assets, setAssets] = useState<any>([]);
  const [showAssetDialog, setShowAssetDialog] = useState<boolean>(false);
  const history = useHistory();
  const [initialValues, setInitialValues] = useState<any>({
    name: "",
    code: ""
  });

  const columns = [
    {
      dataField: "label",
      text: "Asset Name"
    },
    {
      dataField: "imei",
      text: "IMEI"
    },
    {
      dataField: "category",
      text: "Category"
    },
    {
      dataField: "phone",
      text: "Phone"
    },
    {
      dataField: "tracker_type",
      text: "Tracker type"
    },
    {
      dataField: "active",
      text: "Active"
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem to="#" onClick={() => handleOption(product, "edit")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
          </DropdownItem>
          <DropdownItem to="#" onClick={() => handleOption(product, "details")}>
            <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Details
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowAssetDialog(true);
    } else if (type == "new") {
      setInitialValues({
        name: "",
        asset: ""
      });
      setShowAssetDialog(true);
    } else if (type == "details") {
      history.push("/assets/" + item.id);
    }
  }  

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: 25,
    totalSize: assets.length,
    custom: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();
  const { SearchBar } = Search;

  useEffect(() => {

    request.get(Endpoints.assets, {})
      .then((response: AxiosResponse) => {

        const assets = response.data;
        setAssets(assets)
        setLoading(false)

      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  const handleUpdate = (item: any) => {
    const items = assets.map((i: any) => {
      if (i.id === item.id){
        return {...i, ...item};
      }

      return i;
    })
    setAssets(items);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Assets - EYFORT</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Inventory" breadcrumbItem="Assets" />

          <XModal title={"Add an asset"}
                  width={"lg"}
                  open={showAssetDialog}
                  toggle={() => setShowAssetDialog(!showAssetDialog)}
                  onClose={() => setShowAssetDialog(false)}>

            <CreateForm
              fields={AssetFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.assets + "/" + initialValues.id : Endpoints.assets}
              submitButtonLabel={initialValues.id ? "Edit Asset" : "Add Asset"}
              debug={false}
              handleUpdate={handleUpdate}
              onClose={() => setShowAssetDialog(false)} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Assets
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                      <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                        Add asset
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {loading && <Loading label="Loading assets. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={assets}
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col lg="4">
                                  <div className="search-box me-2 mb-2 d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar {...toolkitProps.searchProps} />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </Col>
                              </Row>

                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      // responsive
                                      bordered={false}
                                      striped={false}
                                      defaultSorted={defaultSorted}
                                      selectRow={selectRow}
                                      classes={"table table-hover table-check align-middle table-nowrap"}
                                      headerWrapperClasses={"bg-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>

                              <Row className="align-items-md-center mt-30">
                                <Col className="inner-custom-pagination d-flex">
                                  <div className="d-inline">
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className="text-md-right ms-auto">
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>

            {/*<Col md={12} lg={12} xl={3}>*/}
            {/*  <Card>*/}
            {/*    <CardHeader className="justify-content-between d-flex align-items-center">*/}
            {/*      <CardTitle className="h4">*/}
            {/*        Groups*/}
            {/*      </CardTitle>*/}
            {/*    </CardHeader>*/}

            {/*    <CardBody>*/}

            {/*      {loadingGroups && <Loading label={"Loading groups..."} />}*/}
            {/*      {!loadingGroups &&*/}
            {/*        groups.map((group: any, index: number) => {*/}
            {/*          return <ListGroup key={index}>*/}
            {/*            <ListGroupItem>*/}
            {/*              <ListGroupItemHeading>*/}
            {/*                List group item heading*/}
            {/*              </ListGroupItemHeading>*/}
            {/*              <ListGroupItemText>*/}
            {/*                Donec id elit non mi porta gravida at eget metus. Maecenas sed diam eget risus varius*/}
            {/*                blandit.*/}
            {/*              </ListGroupItemText>*/}
            {/*            </ListGroupItem>*/}
            {/*          </ListGroup>*/}
            {/*        })*/}

            {/*      }*/}

            {/*    </CardBody>*/}
            {/*  </Card>*/}
            {/*</Col>*/}
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Assets;