import { IField } from "../interfaces/inputs/IField";

const productTypes = [
  {value: 'service', text: 'Service'},
  {value: 'consumable', text: 'Consumable'},
  {value: 'stockable', text: 'Stockable'},
]

export const AssetFields: IField[] = [
  { name: "label", type: "text", label: "Name", size: {lg: 4}, position: 1 },
  { name: "imei", type: "text", label: "IMEI", size: {lg: 4}, position: 2 },
  { name: "category", type: "text", label: "Category", size: {lg: 4}, position: 3 },
  { name: "phone", type: "text", label: "Phone", size: {lg: 4}, position: 4 },
  { name: "tracker_type", type: "text", label: "Tracker type", size: {lg: 4}, position: 5 },
  { name: "active", type: "checkbox", label: "Active", size: {lg: 4}, position: 6 },
].sort((a: IField, b: IField) => a.position - b.position);