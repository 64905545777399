import React, { useEffect, useState } from "react";
import { Container, Row, Col, Input, Label, Button, Spinner } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import MetaTags from "react-meta-tags";

import logoLight from "../../assets/images/logo-white.png";
import logoDark from "../../assets/images/logo-colored.svg";
import { Field, Formik, Form } from "formik";
import { SignInScheme } from "../../schemas/SignInSchema";
import { ILogin } from "../../interfaces/ILogin";
import { Urls } from "../../routes/Urls";
import resolveBaseUrl from "../../services";
import { ApiCore } from "../../helpers/api_helper";
import { Endpoints } from "../../utils/Endpoints";
import { getStorableUser, isLoggedIn, setCurrentTenant } from "../../utils/Helpers";
import { values } from "lodash";
import { AxiosResponse } from "axios";

const LoginPage = () => {

  const request = new ApiCore();
  const baseUrl = resolveBaseUrl()
  const credentials : ILogin = {
    username: "",
    password: ""
  };

  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {

    if(isLoggedIn()) {
      loadHomePage();
    }

  })

  const loadHomePage = () => {
    window.location.href = Urls.home;
  }

  const handleSubmit = (values: any) => {
    setLoading(true);

    request.create(Endpoints.login, values)
      .then((response: any) => {

        let { data, headers } = response

        localStorage.setItem("__eyfort__access__token__", "Bearer " + data.accessToken)
        localStorage.setItem("__eyfort__user__", JSON.stringify({
          id: data.id,
          username: data.username,
          setting_id: data.settingId,
          tenant_id: data.tenantId,
          email: data.email
        }))
        setCurrentTenant({id: data.tenantId});    
        setLoading(false);
        loadHomePage();
      })
      .catch(error => {
        setErrorMessage(error)
        setLoading(false)
      });
  };

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login - EYFORT</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100" data-testid="main-wrapper">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto">
              <Col md={8} lg={6} xl={4}>

                <div className="text-center py-5">
                  <div className="mb-4 mb-md-5">
                    <Link to="/sales" className="d-block auth-logo">
                      <img src={logoDark} alt="" height="50" className="auth-logo-dark" />
                      <img src={logoLight} alt="" height="50" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="mb-4">
                    <h5>Welcome Back !</h5>
                    <p>Sign in to continue to EYFORT</p>
                  </div>

                  {errorMessage?.length && <div className="alert alert-danger alert-dismissible fade show" role="alert">
                    <button type="button" className="close" aria-label="Close"><span aria-hidden="true">×</span>
                    </button>
                    <i className="uil uil-exclamation-octagon me-2"></i> {errorMessage}
                  </div>}

                  <Formik
                    initialValues={credentials}
                    validationSchema={SignInScheme}
                    onSubmit={(values) => handleSubmit(values)}>
                    
                    {({ isSubmitting, errors, touched }) => (
                      <Form>

                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="username" type="text"
                                 className={`form-control ${touched.username && errors.username && "is-invalid"}`}
                                 id="input-email"
                                 placeholder="Enter email address" data-testid="input-email" />
                          <Label htmlFor="input-email">Username</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-user-alt ${touched.username && errors.username && "text-danger"}`}></i>
                          </div>
                        </div>

                        <div className="form-floating form-floating-custom mb-3">
                          <Field name="password" type="password"
                                 className={`form-control ${touched.password && errors.password && "is-invalid"}`}
                                 id="input-password"
                                 placeholder="Enter password"  data-testid="input-password" />
                          <Label htmlFor="input-password">Password</Label>
                          <div className="form-floating-icon">
                            <i className={`fa fa-lock ${touched.password && errors.password && "text-danger"}`}></i>
                          </div>
                        </div>

                        <div className="mt-3">
                          <Button color="primary"
                                   data-testid="login-button"
                                  disabled={isSubmitting && loading}
                                  className="btn btn-primary w-100" type="submit">
                            {isSubmitting && loading ? <Spinner size={"sm"} data-testid="loader"></Spinner> : "Log in"}
                          </Button>
                        </div>
                      </Form>
                    )}

                  </Formik>

                  <div className="mt-5 text-center text-muted">
                    <Link to={Urls.auth.resetPassword}>Forgot password?</Link>
                  </div>

                  <div className="mt-5 text-center text-muted">
                    <p>Don&apos;t have an account ? <a href='/register'
                                                          className="fw-medium text-decoration-underline"> Signup </a>
                    </p>
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col xl={12}>
                <div className="text-center text-muted p-4">
                  <p className="mb-0">&copy; {" "}{new Date().getFullYear()} EYFORT</p>
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LoginPage;
